import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  gameIcons: {
    display: 'flex',
    maxWidth: 414,
    height: 109,
    '@media (max-width:425px)': {
      height: 70
    }
  },
  imageBox: {
    maxWidth: 160,
    width: '100%',
    display: 'block',
    position: 'relative'
  },
  gameTabIcon: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    border: '1px solid #13324c',
    borderBottom: 'none',
    color: '#ffffff',
    // height: 70,
    background: 'inherit',
    borderRadius: '10px 10px 0 0',
    '&:hover': {
      cursor: 'pointer'
    },
    '& img': {
      maxWidth: '145px !important'
      // maxHeight: '66px'
    },
    backgroundColor: '#142241'
    // backgroundColor: props=> props.variant === "primary" ?
  },
  sellPrice: {
    fontSize: 22,
    fontWeight: 700,
    // lineHeight: '48.9px',
    textTransform: 'uppercase',
    letterSpacing: 0.21
  },
  active: {
    backgroundColor: '#ffffff',
    color: '#142241'
  },
  alignCenter: {
    textAlign: 'center'
  },
  goldTitle: {
    display: 'inline-block',
    fontSize: 32,
    fontWeight: 700,
    position: 'relative',
    color: '#e9b109',
    '@media (max-width:425px)': {
      fontSize: 23
    }
  },
  '@media (max-width:375px)': {
    gameTabIcon: {
      '& img': {
        maxWidth: '125px !important',
        width: '100%'
      }
    }
  }
  // widget: {
  //   border: '1px solid #ddd'
  //   // borderBottom: "1px solid #56CCF2"
  // }
}))

const StyledGameVersionTabs = ({
  activeTab,
  onChange,
  className,
  products,
  currency,
  onlyImage
}) => {
  const classes = useStyles()

  return (
    <div align='center'>
      <div className={clsx(classes.gameIcons, className)}>
        {products.map((product, index) => {
          const sellPrice = product.sellPriceInCurrencies.find(
            priceItem => priceItem.currency === currency
          )
          return (
            <div
              key={`${product.id}-tab`}
              className={clsx(
                classes.gameTabIcon,
                activeTab === index && classes.active,
                onlyImage && classes.alignCenter
              )}
              onClick={e => onChange(index)}
            >
              {/* <div className={classes.imageBox}> */}
              {/*  <img src={product.image.url} alt={product.name} /> */}
              {/* </div> */}
              <Typography
                component='div'
                className={classes.goldTitle}
                align='center'
              >
                {product.name}
              </Typography>
              {!onlyImage && (
                <div>
                  <Typography
                    component='span'
                    className={classes.sellPrice}
                    align='center'
                  >
                    {sellPrice.localized}
                    /M
                  </Typography>
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

StyledGameVersionTabs.propTypes = {
  activeTab: PropTypes.number,
  onChange: PropTypes.func,
  className: PropTypes.string,
  onlyImage: PropTypes.bool,
  activeTabClassName: PropTypes.string
}

StyledGameVersionTabs.defaultProps = {
  activeTab: 0
}

export default StyledGameVersionTabs
