import React from 'react';
import Image from 'next/image';
import {
  CardContent,
  CardMedia,
  Typography,
  Box,
  InputAdornment
} from '@material-ui/core';
import HiddenCss from '@material-ui/core/Hidden/HiddenCss';
import * as PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { GameVersionEnum } from 'src/core/enums';
import { useRouter } from 'next/router';
import FilledInput from '../FormikFilledInput';
import { useStyles } from './styled';
import { roundTo } from '../../core/utils';
import { MainButton } from '../Button';
import { showSellZopimMessage } from '../../core/zopim';

const defaultValues = {
  [GameVersionEnum.OSRS]: 50,
  [GameVersionEnum.RS3]: 250
};

const SellGoldCard = ({ product }) => {
  const router = useRouter();
  const classes = useStyles();

  const Schema = Yup.object().shape({
    quantity: Yup.number()
      .min(product.minBuyQuantity)
      .required('This field is required.'),
    price: Yup.number()
  });

  const handleSubmit = async values => {
    showSellZopimMessage(values.quantity, product.name);
  };

  const calculatePrice = qty => roundTo(qty * product.buyPrice.amount, 4);

  return (
    <Formik
      initialValues={{
        quantity: undefined,
        price: undefined
      }}
      validationSchema={Schema}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({ values, setValues, setFieldValue }) => (
        <div className={clsx(classes.root, classes.notRoundedCorner)}>
          <HiddenCss xsDown>
            <Box className={classes.cardHeader} justifyContent="center">
              <Typography
                component="div"
                className={classes.goldTitle}
                align="center"
              >
                {product.name}
              </Typography>
              {/* <CardMedia */}
              {/*  className={classes.media} */}
              {/*  component="img" */}
              {/*  image={product.image.url} */}
              {/*  title={product.name} */}
              {/*  align="center" */}
              {/*  alt={product.name} */}
              {/* /> */}
            </Box>
          </HiddenCss>
          <CardContent className={classes.content}>
            <Typography align="center" className={classes.cardTitle}>
              Sell {product.gameVersion} Gold
            </Typography>
            <Typography align="center" className={classes.cardDesc}>
              How much {product.gameVersion} Gold would you like to sell?
            </Typography>
            <Typography align="center" className={classes.cardInfo}>
              We pay up to ${product.buyPrice.amount}
              /M
            </Typography>
            <Form>
              <div className={classes.prices}>
                <div className={classes.priceItem}>
                  <div className={classes.pricePrefix}>
                    <Typography
                      component="div"
                      className={classes.pricePrefixSign}
                    >
                      M
                    </Typography>
                    <Typography
                      component="div"
                      className={classes.pricePrefixText}
                    >
                      amount
                    </Typography>
                  </div>
                  <FilledInput
                    name="quantity"
                    variant="filled"
                    placeholder="0"
                    numeric
                    small="true"
                    className={clsx(classes.priceInput, classes.input)}
                    InputProps={{
                      className: classes.fieldInput
                    }}
                    onChangeCapture={e => {
                      const targetValue = e.target.value;
                      if (targetValue[0] === '0') {
                        setFieldValue('quantity', values.quantity);
                        return null;
                      }
                      const price = calculatePrice(targetValue);
                      setFieldValue('price', price);
                      setFieldValue('quantity', targetValue);
                    }}
                    FormHelperTextProps={{
                      className: clsx(classes.errorText, classes.quantityError)
                    }}
                  />
                </div>
                <Image
                  src="/images/2-layers.png"
                  width={16}
                  height={28}
                  alt="sync"
                  loading="eager"
                />
                <div className={classes.priceItem}>
                  <div className={classes.pricePrefix}>
                    <Typography
                      component="div"
                      className={classes.pricePrefixSign}
                    >
                      USD
                    </Typography>
                    <Typography
                      component="div"
                      className={classes.pricePrefixText}
                    >
                      amount
                    </Typography>
                  </div>
                  <FilledInput
                    name="price"
                    // id="price"
                    placeholder="0"
                    variant="filled"
                    numeric
                    small="true"
                    InputProps={{
                      className: classes.fieldInput,
                      startAdornment: (
                        <InputAdornment
                          className={classes.tilda}
                          position="start"
                        >
                          ~
                        </InputAdornment>
                      )
                    }}
                    inputProps={{
                      readOnly: true
                    }}
                    className={clsx(classes.priceInput, classes.input)}
                  />
                </div>
              </div>
              <MainButton
                color="secondary"
                arrows
                fullWidth
                className={classes.button}
                classes={{
                  label: classes.buttonLabel
                }}
              >
                Sell {product.gameVersion} gold
              </MainButton>
            </Form>
          </CardContent>
        </div>
      )}
    </Formik>
  );
};

SellGoldCard.propTypes = {
  currency: PropTypes.string,
  product: PropTypes.object,
  isWidget: PropTypes.bool,
  hideMediaOnMobile: PropTypes.bool
};

export default SellGoldCard;
